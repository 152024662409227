<template>
  <div class="">
    <div class="hero-image-or ">
      <div class="bg-dense-or justify-content-center align-items-center d-flex">
        <div class="mx-4">
          <div class="big-text-header-bg ">
            <span>{{ $t("message.content62") }}</span>
          </div>
          <div class="text-header-bg mx-4">
            <p>{{ $t("message.content63") }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="">
      <div class="auto-rl-eight-to-two  mb-5 pt-5">
        <div class="display-center ">
          <div class="row col-12">
            <!--  -->
            <div class="col-12 col-md-6 ">
              <div class="text-header-od">
                <p>
                  {{ $t("message.content64") }}
                </p>
              </div>

              <div class="text-small-od ">
                <p>
                  {{ $t("message.content65") }}
                </p>
              </div>
            </div>

            <div class="col-12 col-md-6  custom-open">
              <div class="text-header-od">
                <p>
                  {{ $t("message.content66") }}
                </p>
              </div>
            </div>
          </div>

          <div class="row col-12 ">
            <div class="col-12 col-md-6">
              <div>
                <b-card class="my-2">
                  <div class="text-title-card-od">
                    <span>
                      {{ $t("message.content67") }}{{ "."
                      }}{{ $t("message.content68") }}
                    </span>
                  </div>
                  <div>
                    <a
                      href="https://th1337639403eyem.trustpass.alibaba.com/"
                      class="text-title-sub-card-od"
                    >
                      {{ $t("message.content69") }}
                      <br />
                    </a>
                      {{ $t("message.content699") }}
                      <br />
                    <a
                      href="mailto:neen@mondail.co.th"
                      class="text-title-sub-card-od"
                    >
                      {{ $t("message.content6999") }}
                    </a>
                  </div>
                </b-card>
              </div>

              <div>
                <b-card class="my-2">
                  <div>
                    <span class="text-title-card-od">
                      {{ $t("message.content70") }}{{ "."
                      }}{{ $t("message.content71") }}
                    </span>
                  </div>
                  <div>
                    <a
                      href="https://th1337639403eyem.trustpass.alibaba.com/"
                      class="text-title-sub-card-od"
                    >
                      {{ $t("message.content72") }}
                      <br />
                    </a>
                      {{ $t("message.content722") }}
                      <br />
                    <a
                      href="mailto:neen@mondail.co.th"
                      class="text-title-sub-card-od"
                    >
                      {{ $t("message.content7222") }}
                    </a>
                  </div>
                </b-card>
              </div>

              <div>
                <b-card class="my-2">
                  <div>
                    <span class="text-title-card-od">
                      {{ $t("message.content73") }}{{ "."
                      }}{{ $t("message.content74") }}
                    </span>
                  </div>
                  <div>
                    <a
                      href="mailto:neen@mondail.co.th"
                      class="text-title-sub-card-od"
                    >
                      {{ $t("message.content75") }}
                    </a>
                  </div>
                </b-card>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <!--  -->
              <div class="col-12 col-md-6 custom-none pt-5">
                <div class="text-header-od">
                  <p>
                    {{ $t("message.content66") }}
                  </p>
                </div>
              </div>
              <!--  -->
              <div>
                <b-card class="my-2">
                  <div class="row">
                    <div class="col-1 ">
                      <img class="icon-faq" src="@/assets/icon/Icon_FAQ.png" />
                    </div>
                    <div class="col-10 ">
                      <p class="text-title-card-od">
                        {{ $t("message.content76") }}
                      </p>
                    </div>
                  </div>
                  <div class="auto-left-nine auto-right-nine">
                    <span class="text-title-sub-small-card-od ">
                      {{ $t("message.content77") }}
                    </span>
                  </div>
                </b-card>

                <b-card class="my-2">
                  <div class="row">
                    <div class="col-1">
                      <img class="icon-faq" src="@/assets/icon/Icon_FAQ.png" />
                    </div>
                    <div class="col-10 ">
                      <p class="text-title-card-od">
                        {{ $t("message.content78") }}
                      </p>
                    </div>
                  </div>
                  <div class="auto-left-nine auto-right-nine">
                    <span class="text-title-sub-small-card-od ">
                      {{ $t("message.content79") }}
                    </span>
                  </div>
                </b-card>

                <b-card class="my-2">
                  <div class="row">
                    <div class="col-1">
                      <img class="icon-faq" src="@/assets/icon/Icon_FAQ.png" />
                    </div>
                    <div class="col-10 ">
                      <p class="text-title-card-od">
                        {{ $t("message.content80") }}
                      </p>
                    </div>
                  </div>
                  <div class="auto-left-nine auto-right-nine">
                    <span class="text-title-sub-small-card-od ">
                      {{ $t("message.content81") }}
                    </span>
                  </div>
                </b-card>

                <b-card class="my-2">
                  <div class="row">
                    <div class="col-1">
                      <img class="icon-faq" src="@/assets/icon/Icon_FAQ.png" />
                    </div>
                    <div class="col-10 ">
                      <p class="text-title-card-od">
                        {{ $t("message.content82") }}
                      </p>
                    </div>
                  </div>
                  <div class="auto-left-nine auto-right-nine">
                    <span class="text-title-sub-small-card-od ">
                      {{ $t("message.content83") }}
                    </span>
                  </div>
                </b-card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div>
      <gettouch />
    </div>
  </div>
</template>

<script>
import Gettouch from "@/components/Gettouch.vue";

export default {
  mounted() {},
  components: {
    gettouch: Gettouch,
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/order";
@import "@/assets/scss/about";
@import "@/assets/scss/main";
</style>
